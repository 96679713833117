.iframe-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .fullscreen-iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
  